import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';

import { getCompletionReports } from './report-action';

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    completionReports: [],
    getCompletionReportStatus: ReducerStatus.IDLE,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCompletionReports.pending, (state) => {
        state.getCompletionReportStatus = ReducerStatus.LOADING;
      })
      .addCase(getCompletionReports.fulfilled, (state, action) => {
        state.completionReports = action.payload;
        state.getCompletionReportStatus = ReducerStatus.SUCCEEDED;
      })
      .addCase(getCompletionReports.rejected, (state) => {
        state.getCompletionReportStatus = ReducerStatus.FAILED;
      });
  },
});

export default reportSlice;
