import { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Text,
  Spacer,
  VStack,
  ScaleFade,
  Grid,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import PagingControl from 'components/PagingControl';
import CourseItem from 'components/course/CourseItem';
import CourseItemLoading from 'components/course/CourseItemLoading';

function HomePage() {
  document.title = 'Skill Quest - Home';
  const columnLayout = useBreakpointValue({
    base: '1fr',
    sm: '1fr 1fr',
    md: '1fr 1fr',
    lg: '1fr 1fr 1fr',
    xl: '1fr 1fr 1fr 1fr',
  });
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pageSize = 12;

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `/courses?currentPage=${currentPage}&pageSize=${pageSize}`
      );
      const { data, metaData } = response.data;
      setCourses(data);
      setTotalCount(metaData.totalItems);
      setHasNext(metaData.hasNext);
      setIsLoading(false);
    };
    fetchCourses();
  }, [currentPage]);

  return (
    <Box ml={{ base: 0, md: 20 }} p="10">
      <Text flex={1} fontSize="xl" fontWeight="bold" mb={5} colorScheme="gray">
        Browse Our Courses
      </Text>
      <VStack minHeight="100vh" align="stretch">
        {isLoading ? (
          <Grid templateColumns={columnLayout} gap={6}>
            {[...Array(12)].map(() => (
              <GridItem key={uuidv4()} w="100%">
                <CourseItemLoading />
              </GridItem>
            ))}
          </Grid>
        ) : (
          <ScaleFade initialScale={0.9} in={!isLoading}>
            <VStack minHeight="100vh" align="stretch">
              <Grid templateColumns={columnLayout} gap={6}>
                {courses.map((course) => (
                  <GridItem w="100%" key={course.id}>
                    <CourseItem
                      id={course.id}
                      image={course.thumbnail}
                      category={course.category.name}
                      name={course.name}
                      description={course.description}
                    />
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          </ScaleFade>
        )}

        <Spacer mt={12} />

        <PagingControl
          currentPage={currentPage}
          onGoToPage={(newPageNumber) => setCurrentPage(newPageNumber)}
          pageSize={pageSize}
          totalCount={totalCount}
          hasNext={hasNext}
          totalCountDescription="courses available"
        />
      </VStack>
    </Box>
  );
}

export default HomePage;
