import { RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import DataTable from 'react-data-table-component';

import axios from 'axios';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { AsyncSelect, Select } from 'chakra-react-select';

import {
  Box,
  Text,
  Flex,
  VStack,
  Divider,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
  IconButton,
  InputGroup,
  InputRightElement,
  Input,
  Spacer,
  Badge,
  useToast,
  useColorModeValue,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { SiMicrosoftexcel } from 'react-icons/si';

import FormikFormControl from 'components/common/forms/FormikFormControl';
import TableItemLoader from 'components/common/tables/TableItemLoader';

import {
  COMPLETED,
  IN_PROGRESS,
  NOT_STARTED,
} from 'utils/constants/reportStatus';

import { useSelector, useDispatch } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import { getCompletionReports } from 'store/modules/report/report-action';
import { useMsal } from '@azure/msal-react';
import { completionReportColumns, getReportSummary } from 'utils/reportHelper';
import exportAsCsv from 'utils/exportCsv';

function ReportPage() {
  const departmentOptions = [
    { label: 'IS', value: 'IS' },
    { label: 'SG', value: 'SG' },
    { label: 'TR', value: 'TR' },
    { label: 'TSCONS', value: 'TSCONS' },
    { label: 'TSCORP', value: 'TSCORP' },
  ];

  const { accounts } = useMsal();
  const { idTokenClaims, name } = accounts[0];
  const { roles } = idTokenClaims;

  const toast = useToast();
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState(departmentOptions);
  const [managers, setManagers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isError, setIsError] = useState(false);
  const [isManagerMode, setIsManagerMode] = useState(false);

  const completionReports = useSelector(
    (state) => state.report.completionReports
  );
  const status = useSelector((state) => state.report.getCompletionReportStatus);

  const filteredReports = completionReports.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleChange = (setter) => (selectedOptions) => {
    setter(selectedOptions);
  };

  const handleDepartmentChange = handleChange(setDepartments);
  const handleManagerChange = handleChange(setManagers);
  const handleCourseChange = handleChange(setCourses);

  const handleResetFields = () => {
    setDepartments(departmentOptions);
    setManagers([]);
    setCourses([]);
  };

  useEffect(() => {
    if (courses.length > 0) setIsError(false);
  }, [courses]);

  useEffect(() => {
    if (roles.includes('Manager') && !roles.includes('Trainer'))
      setIsManagerMode(true);
  }, [roles]);

  useEffect(() => {
    if (status === ReducerStatus.FAILED)
      toast({
        title: 'Error',
        description: 'Something went wrong with the request',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
  }, [status, toast]);

  const loadManagers = debounce((inputValue, callback) => {
    axios
      .get(
        `/users?query=${inputValue.trim()}&pageSize=${5}&isSupervisor=${true}`
      )
      .then((response) => {
        callback(
          response.data.data?.map((user) => ({
            label: user.fullName,
            value: user.id,
          }))
        );
      })
      .catch(() => {
        callback([]);
      });
  }, 1000);

  const loadCourses = debounce((inputValue, callback) => {
    axios
      .get(`/courses?query=${inputValue.trim()}&pageSize=${5}`)
      .then((response) => {
        callback(
          response.data.data?.map((courseItem) => ({
            label: courseItem.name,
            value: courseItem.id,
          }))
        );
      })
      .catch(() => {
        callback([]);
      });
  }, 1000);

  const handleGenerate = async () => {
    if (courses.length === 0) {
      setIsError(true);
      return;
    }

    dispatch(
      getCompletionReports({
        courses,
        managers,
        departments,
      })
    );
  };

  const handleExport = () => {
    if (selectedData.length > 0)
      exportAsCsv(selectedData, 'course-completion-report');
    else exportAsCsv(completionReports, 'course-completion-report');
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Box minH="100vh" ml={{ base: 0, md: 20 }} p="10">
      <Text my={4} fontSize="2xl">
        Reports
      </Text>

      <Box p={4} boxShadow="md" borderWidth="1px" borderRadius="lg">
        <Flex
          justify="streched"
          direction={{ base: 'column', lg: 'row' }}
          gap={3}
        >
          <Box flex={1}>
            <Text as="samp" fontSize="xs">
              <strong>SELECT FILTER</strong>
            </Text>
            <VStack mt={4} spacing={6} align="stretch">
              <Flex
                gap={5}
                direction={{
                  base: 'column',
                  xl: 'row',
                }}
              >
                <FormikFormControl label="Department" helperText="">
                  <Select
                    placeholder="Select department"
                    value={departments}
                    onChange={handleDepartmentChange}
                    options={departmentOptions}
                    isMulti
                    isClearable
                  />
                </FormikFormControl>

                <FormikFormControl label="Manager" helperText="">
                  <AsyncSelect
                    isDisabled={isManagerMode}
                    placeholder={
                      isManagerMode ? `${name} (You)` : 'Search manager'
                    }
                    loadOptions={loadManagers}
                    value={managers}
                    onChange={handleManagerChange}
                    isMulti
                    isClearable
                  />
                </FormikFormControl>
              </Flex>

              <FormikFormControl
                label="Courses"
                helperText=""
                errors={isError === true ? 'Course is required' : 'test'}
                touched={isError}
              >
                <AsyncSelect
                  placeholder="Search courses"
                  loadOptions={loadCourses}
                  value={courses}
                  onChange={handleCourseChange}
                  isMulti
                  isClearable
                />
              </FormikFormControl>

              <Flex justify="right" gap={5}>
                <Button
                  onClick={handleResetFields}
                  colorScheme="blue"
                  variant="outline"
                  px={10}
                >
                  Reset
                </Button>
                <Button
                  colorScheme="red"
                  px={10}
                  isLoading={status === ReducerStatus.LOADING}
                  onClick={handleGenerate}
                >
                  Generate
                </Button>
              </Flex>
            </VStack>
          </Box>

          <Box height="100%">
            <Divider orientation="vertical" />
          </Box>

          <Box w={{ base: '100%', lg: '50%' }}>
            <Text as="samp" fontSize="xs">
              <strong> REPORT SUMMARY</strong>
            </Text>
            <TableContainer mt={4}>
              <Table size="sm">
                {completionReports.length > 0 && (
                  <TableCaption>
                    course(s) activities: {completionReports[0].totalActivities}
                  </TableCaption>
                )}

                <Thead>
                  <Tr>
                    <Th>type</Th>
                    <Th>percentage</Th>
                    <Th isNumeric>count</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {status === ReducerStatus.LOADING ? (
                    <TableItemLoader numberOfRows={3} numberOfColumns={3} />
                  ) : (
                    <>
                      <Tr>
                        <Td>
                          <Badge colorScheme="green">{COMPLETED}</Badge>
                        </Td>
                        <Td>
                          {
                            getReportSummary(completionReports, COMPLETED)
                              .percentage
                          }
                          %
                        </Td>
                        <Td isNumeric>
                          {getReportSummary(completionReports, COMPLETED).count}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Badge colorScheme="blue">{IN_PROGRESS}</Badge>
                        </Td>
                        <Td>
                          {
                            getReportSummary(completionReports, IN_PROGRESS)
                              .percentage
                          }
                          %
                        </Td>
                        <Td isNumeric>
                          {
                            getReportSummary(completionReports, IN_PROGRESS)
                              .count
                          }
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Badge colorScheme="gray">{NOT_STARTED}</Badge>
                        </Td>
                        <Td>
                          {
                            getReportSummary(completionReports, NOT_STARTED)
                              .percentage
                          }
                          %
                        </Td>
                        <Td isNumeric>
                          {
                            getReportSummary(completionReports, NOT_STARTED)
                              .count
                          }
                        </Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Flex>
      </Box>

      <Box mt={5} w="100%" p={4} color="gray.500" borderWidth="1px">
        <Flex align="center" m={2}>
          <Button
            onClick={handleExport}
            rightIcon={<SiMicrosoftexcel />}
            size="sm"
            colorScheme="blue"
            variant="outline"
          >
            Export
          </Button>
          <Spacer />
          <IconButton
            onClick={handleGenerate}
            aria-label="Reload table"
            variant="link"
            icon={<RepeatIcon />}
          />

          <InputGroup width={{ base: '100%', md: '350px' }}>
            <InputRightElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputRightElement>
            <Input
              value={searchText}
              onChange={handleSearchTextChange}
              name="query"
              placeholder="Search by name"
            />
          </InputGroup>
        </Flex>

        <Divider mt={4} />

        <DataTable
          theme={useColorModeValue('default', 'dark')}
          progressPending={status === ReducerStatus.LOADING}
          columns={completionReportColumns}
          data={filteredReports}
          onSelectedRowsChange={({ selectedRows }) =>
            setSelectedData(selectedRows)
          }
          progressComponent={
            <Center my={10}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          }
          selectableRows
          pagination
        />
      </Box>
    </Box>
  );
}

export default ReportPage;
