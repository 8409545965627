import { configureStore } from '@reduxjs/toolkit';

import course from 'store/modules/course/course-slice';
import category from 'store/modules/category/category-slice';
import progression from 'store/modules/progression/progression-slice';
import quest from 'store/modules/quest/quest-slice';
import subquest from 'store/modules/subquest/subquest-slice';
import report from 'store/modules/report/report-slice';

import adminLearningPlan from 'modules/admin/learningPlan/store/admin-learningplan-slice';
import adminLearningPlanCourse from 'modules/admin/learningPlanCourse/store/admin-learningplan-course-slice';
import adminGrowthStage from 'modules/admin/growthStage/store/admin-growthstage-slice';
import adminSubQuest from 'modules/admin/subQuest/store/admin-subquest-slice';
import companionSlice from 'companion/store/companion-slice';
import ui from './modules/ui-slice';

const store = configureStore({
  reducer: {
    ui: ui.reducer,
    companion: companionSlice.reducer,
    course: course.reducer,
    category: category.reducer,
    progression: progression.reducer,
    quest: quest.reducer,
    subquest: subquest.reducer,
    report: report.reducer,
    adminLearningPlan: adminLearningPlan.reducer,
    adminLearningPlanCourse: adminLearningPlanCourse.reducer,
    adminGrowthStage: adminGrowthStage.reducer,
    adminSubQuest: adminSubQuest.reducer,
  },
});

export default store;
