import PropTypes from 'prop-types';
import { useEffect } from 'react';

import {
  useColorModeValue,
  Text,
  Box,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  //   Tooltip,
  //   Button,
} from '@chakra-ui/react';

import {
  DragHandleIcon,
  CheckCircleIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import { MdQuiz, MdVideoLibrary, MdVideoSettings } from 'react-icons/md';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { HiDotsVertical } from 'react-icons/hi';
import { RiDraftFill } from 'react-icons/ri';
import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { getSubQuestById } from 'store/modules/subquest/subquest-action';
import { useSelector, useDispatch } from 'react-redux';

import { ReducerStatus } from 'utils/constants';
import { removeHtmlTags } from 'utils/textParser';

function SubQuestAdminItem({ id, questId, isEditable, subQuest }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const dispatch = useDispatch();

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const status = useSelector((state) => state.subquest.getSubQuest.status);
  const getSubQuest = useSelector((state) => state.subquest.getSubQuest);
  const selectedSubQuest = useSelector((state) => state.subquest.subQuest);

  useEffect(() => {
    if (getSubQuest.status === ReducerStatus.SUCCEEDED && selectedSubQuest) {
      const typeId = selectedSubQuest.type.id;
      if (typeId === 1) dispatch(subquestActions.toggleVideoUpdateModal(true));
      if (typeId === 4) dispatch(subquestActions.toggleQuizUpdateModal(true));
      if (typeId === 10)
        dispatch(subquestActions.toggleInteractiveVideoUpdateModal(true));
    }
  }, [getSubQuest, selectedSubQuest, dispatch]);

  const editSubQuest = (subQuestId) => {
    dispatch(subquestActions.setQuestId(questId));
    dispatch(getSubQuestById({ questId, subQuestId }));
  };

  const removeSubQuest = (subQuestId) => {
    dispatch(subquestActions.setQuestId(questId));
    dispatch(subquestActions.setSubQuestIdForRemoval(subQuestId));
    dispatch(subquestActions.toggleRemoveModal(true));
  };

  //   const generateQuiz = (targetSubQuest) => {
  //     dispatch(companionActions.setMode(CompanionMode.QUIZ_GENERATOR));
  //     dispatch(
  //       companionActions.setContext(targetSubQuest.subQuestVideo.transcript)
  //     );
  //     dispatch(companionActions.setConversations([]));
  //     dispatch(companionActions.setModal(true));
  //     dispatch(companionActions.setInitialPrompt(true));
  //   };

  const iconColor = useColorModeValue('teal', 'white');

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={6} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={4} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              size="lg"
              color="teal"
              aria-label="Re-order sub-quest"
              icon={<DragHandleIcon />}
            />
            {subQuest.type.id === 1 && (
              <Icon as={MdVideoLibrary} boxSize={10} mx={4} color={iconColor} />
            )}

            {subQuest.type.id === 4 && (
              <Icon as={MdQuiz} boxSize={10} mx={4} color={iconColor} />
            )}

            {subQuest.type.id === 10 && (
              <Icon
                as={MdVideoSettings}
                boxSize={10}
                mx={4}
                color={iconColor}
              />
            )}

            <Box flex={1}>
              <Text fontWeight={500} fontSize="md" mb={1}>
                {subQuest.name}
              </Text>
              <Text fontSize="sm">{removeHtmlTags(subQuest.objective)}</Text>
            </Box>

            {subQuest.isPublished ? (
              <CheckCircleIcon color="green" />
            ) : (
              <RiDraftFill color="purple" />
            )}

            {isEditable && (
              <Menu>
                <MenuButton
                  isLoading={status === ReducerStatus.LOADING}
                  as={IconButton}
                  aria-label="Options"
                  icon={<HiDotsVertical />}
                  variant="text"
                />
                <MenuList>
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={() => {
                      editSubQuest(subQuest.id);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                      removeSubQuest(subQuest.id);
                    }}
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Box>
      </Box>
    </div>
  );
}

SubQuestAdminItem.defaultProps = {
  id: 1,
};

SubQuestAdminItem.propTypes = {
  id: PropTypes.number,
  questId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  subQuest: PropTypes.object.isRequired,
};

export default SubQuestAdminItem;
