import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Flex, Text, Link } from '@chakra-ui/react';

function Footer() {
  return (
    <Flex ml={{ base: 0, md: 20 }} p={10} justify="right">
      <Text>
        Copyright © 2024 Trend Micro Inc. All rights reserved - v1.2.0-beta -{' '}
        <Link
          href="https://trendmicro.atlassian.net/wiki/spaces/SQK/pages/864004726/1200+-+Release+Notes"
          isExternal
        >
          All Release Notes <ExternalLinkIcon mx="2px" />
        </Link>
      </Text>
    </Flex>
  );
}

export default Footer;
