import PropTypes from 'prop-types';

import {
  Text,
  Box,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import CreateInteractiveVideoMd from 'components/subQuest/modals/interactiveVideos/CreateInteractiveVideoMd';
import CreateQuizMd from 'components/subQuest/modals/quizzes/CreateQuizMd';
import CreateVideoMd from './modals/videos/CreateVideoMd';

function AddSubQuestMd({ isEditable, quest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        display={isEditable ? 'block' : 'none'}
        onClick={onOpen}
        variant="plain"
        aria-label="Add Sub-Quest"
        icon={<AddIcon />}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Sub-Quest</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={10}>
              <Text mb={5}>
                Select which type of sub-quest you wish to create
              </Text>

              <VStack align="stretch" gap="4">
                <CreateVideoMd quest={quest} />
                <CreateInteractiveVideoMd quest={quest} />
                <CreateQuizMd quest={quest} />
              </VStack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="outline"
              outline
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

AddSubQuestMd.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  quest: PropTypes.object.isRequired,
};

export default AddSubQuestMd;
