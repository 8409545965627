import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCompletionReports = createAsyncThunk(
  'report/getCompletionReports',
  async ({ courses, managers, departments }, { rejectWithValue }) => {
    try {
      let params = '/admin/reports?';

      for (let i = 0; i < courses.length; i += 1)
        params += `CourseIds=${courses[i].value}&`;

      for (let i = 0; i < departments.length; i += 1)
        params += `Departments=${departments[i].value}&`;

      for (let i = 0; i < managers.length; i += 1)
        params += `ManagerIds=${managers[i].value}&`;

      const response = await axios.get(params.slice(0, -1));
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export default getCompletionReports;
