import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Heading,
  Text,
  Center,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';

function ResponseModal({
  isVisible,
  isSuccess,
  errorObject,
  title,
  description,
  onSubmit,
}) {
  let defaultTitle = isSuccess ? 'Success' : 'Error';
  if (title) defaultTitle = title;

  let defaultDescription = isSuccess
    ? 'The operation was completed successfully.'
    : 'We apologize for the inconvenience. It appears that something went wrong with your request. Kindly try again later. If the issue persists, please contact our support team for assistance. Thank you for your understanding.';
  if (description) defaultDescription = description;

  let parsedErrors = [];

  if (errorObject)
    Object.keys(errorObject).forEach((key) => {
      if (Array.isArray(errorObject[key])) {
        parsedErrors = parsedErrors.concat(errorObject[key]);
      }
    });

  return (
    <Modal size="md" isOpen={isVisible} onClose={() => onSubmit()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box py={10} px={6}>
            {isSuccess ? (
              <Center>
                <CheckCircleIcon boxSize="50px" color="green.500" />
              </Center>
            ) : (
              <Center>
                <Flex
                  flexDirection="column"
                  justify="center"
                  alignItems="center"
                  bg="red.500"
                  rounded="50px"
                  w="55px"
                  h="55px"
                  textAlign="center"
                >
                  <CloseIcon boxSize="20px" color="white" />
                </Flex>
              </Center>
            )}

            <Center>
              <Heading as="h2" size="xl" mt={6} mb={2}>
                {defaultTitle}
              </Heading>
            </Center>

            <Center>
              <Text color="gray.500">
                {errorObject?.data?.title || defaultDescription}
              </Text>
            </Center>

            {parsedErrors.length > 0 && !isSuccess && (
              <UnorderedList my={4}>
                {parsedErrors.map((error) => (
                  <ListItem key={`${error}`}>{error}</ListItem>
                ))}
              </UnorderedList>
            )}
          </Box>

          <Center>
            <Button
              colorScheme={isSuccess ? 'green' : 'red'}
              size="lg"
              px={10}
              onClick={() => onSubmit()}
            >
              Proceed
            </Button>
          </Center>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}

ResponseModal.defaultProps = {
  errorObject: null,
  title: null,
  description: null,
};

ResponseModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  errorObject: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default ResponseModal;
