import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { FiHome, FiLayers, FiBookOpen, FiSettings } from 'react-icons/fi';

import { useMsal } from '@azure/msal-react';
import NavItem from './NavItem';

const LinkItemsForTrainers = [
  { name: 'HOME', icon: FiHome, to: '/', base: 'home' },
  { name: 'COURSES', icon: FiLayers, to: '/admin/courses', base: 'courses' },
  {
    name: 'CATEGORIES',
    icon: FiBookOpen,
    to: '/admin/categories',
    base: 'categories',
  },
  {
    name: 'REPORTS',
    icon: FiSettings,
    to: '/reports',
    base: 'reports',
  },
];

const LinkItemsForTrainees = [
  { name: 'HOME', icon: FiHome, to: '/', base: 'home' },
];

function SidebarContent({ onClose, ...rest }) {
  const { accounts } = useMsal();
  const { idTokenClaims } = accounts[0];
  const { roles } = idTokenClaims;

  return (
    <Box
      bg={useColorModeValue('#d92e48', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: '80px' }}
      pos="absolute"
      minHeight="100%"
      {...rest}
    >
      <Flex justify="end" display={{ base: 'flex', md: 'none' }}>
        <Box p="4">
          <CloseButton
            display={{ base: 'flex', md: 'none' }}
            color="white"
            onClick={onClose}
          />
        </Box>
      </Flex>

      <Grid
        display={{ base: 'grid', md: 'none' }}
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        {roles?.find((role) => role === 'Trainer') ? (
          <Box>
            {LinkItemsForTrainers.map((link) => (
              <GridItem w="100%" key={link.name}>
                <NavItem to={link.to} base={link.base} icon={link.icon}>
                  {link.name}
                </NavItem>
              </GridItem>
            ))}
          </Box>
        ) : (
          <Box>
            {LinkItemsForTrainees.map((link) => (
              <GridItem w="100%" key={link.name}>
                <NavItem to={link.to} base={link.base} icon={link.icon}>
                  {link.name}
                </NavItem>
              </GridItem>
            ))}
          </Box>
        )}
      </Grid>

      <Box hideBelow="md">
        {roles?.find((role) => role === 'Trainer') ? (
          <Box>
            {LinkItemsForTrainers.map((link) => (
              <NavItem
                key={link.name}
                to={link.to}
                base={link.base}
                icon={link.icon}
              >
                {link.name}
              </NavItem>
            ))}
          </Box>
        ) : (
          <Box>
            {LinkItemsForTrainees.map((link) => (
              <NavItem
                key={link.name}
                to={link.to}
                base={link.base}
                icon={link.icon}
              >
                {link.name}
              </NavItem>
            ))}{' '}
          </Box>
        )}
      </Box>
    </Box>
  );
}

SidebarContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SidebarContent;
