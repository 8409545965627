import { Routes, Route } from 'react-router-dom';
import {
  useMsal,
  useIsAuthenticated,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';

import { InteractionType } from '@azure/msal-browser';

import { useEffect } from 'react';
import axios from 'axios';

import { loginRequest } from 'config/authConfig';

import './App.css';

import RootLayout from 'layout/RootLayout';

import RequireAuth from 'hooks/RequireAuth';
import NotFound from 'components/common/errors/NotFound';
import HomePage from 'pages/HomePage';
import CoursePage from 'pages/CoursePage';
import SubQuestPage from 'pages/SubQuestPage';
import CourseListPage from 'pages/admin/CourseListPage';
import CourseAdminPage from 'pages/admin/CourseAdminPage';
import CategoryListPage from 'pages/admin/CategoryListPage';
import ReportPage from 'pages/admin/ReportPage';

const ROLES = {
  Trainer: 'Trainer',
  Manager: 'Manager',
};

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const acquireTokenSilent = async () => {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      return response.accessToken;
    };

    const setupAxios = async () => {
      axios.defaults.baseURL = process.env.REACT_APP_OM_API_URL;
      axios.interceptors.request.use(
        async (config) => {
          if (!config.headers.Authorization) {
            const authToken = await acquireTokenSilent();
            config.headers.Authorization = `Bearer ${authToken}`;
          }
          return config;
        },
        async (error) => {
          const prevRequest = error?.config;
          if (error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await acquireTokenSilent();
            prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(prevRequest);
          }
          return Promise.reject(error);
        }
      );
    };

    if (!isAuthenticated) {
      setupAxios();
    }
  }, [isAuthenticated, accounts, instance]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/courses/:id" element={<CoursePage />} />
          <Route
            path="/courses/:id/quests/:questId/sub-quests/:subQuestId"
            element={<SubQuestPage />}
          />

          {/* protected routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Trainer]} />}>
            <Route path="/admin/courses" element={<CourseListPage />} />
            <Route path="/admin/courses/:id" element={<CourseAdminPage />} />
            <Route path="/admin/categories" element={<CategoryListPage />} />
          </Route>

          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.Trainer, ROLES.Manager]} />
            }
          >
            <Route path="/reports" element={<ReportPage />} />
          </Route>

          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Route>
      </Routes>
    </MsalAuthenticationTemplate>
  );
}

export default App;
