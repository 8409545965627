import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link as ReactRouterLink } from 'react-router-dom';

import {
  Box,
  Text,
  Flex,
  Table,
  Tr,
  Th,
  Td,
  Badge,
  Link as ChakraLink,
  Tbody,
  Thead,
  Tfoot,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Button,
} from '@chakra-ui/react';

import { fetchCoursesAction } from 'store/modules/course/course-actions';
import { courseActions } from 'store/modules/course/course-slice';

import { SearchIcon, RepeatIcon, AddIcon } from '@chakra-ui/icons';
import RowsPerPage from 'components/common/tables/RowsPerPage';
import TableNavigation from 'components/common/tables/TableNavigation';
import TableItemLoader from 'components/common/tables/TableItemLoader';
import CreateCourseMd from 'components/course/modals/CreateCourseMd';

function CourseListPage() {
  document.title = 'Skill Quest - Admin - Courses';

  const dispatch = useDispatch();

  const courses = useSelector((state) => state.course.courses);
  const status = useSelector((state) => state.course.status);
  const metaData = useSelector((state) => state.course.metaData);

  const [paginationParams, setPaginationParams] = useState({
    query: '',
    currentPage: 1,
    pageSize: 20,
  });

  useEffect(() => {
    dispatch(fetchCoursesAction(paginationParams));
  }, [paginationParams, dispatch]);

  const handleOnRefresh = () => {
    dispatch(fetchCoursesAction(paginationParams));
  };

  const handleParamsChange = (identifier, event) => {
    if (identifier === 'incrementCurrentPage') {
      setPaginationParams((prevParams) => ({
        ...prevParams,
        currentPage: prevParams.currentPage + 1,
      }));
    } else if (identifier === 'decrementCurrentPage') {
      if (paginationParams.currentPage > 1) {
        setPaginationParams((prevParams) => ({
          ...prevParams,
          currentPage: prevParams.currentPage - 1,
        }));
      }
    } else {
      setPaginationParams((prevValues) => ({
        ...prevValues,
        [identifier]: event.target.value,
      }));
    }
  };

  const handleOpenCreateCourseModal = () => {
    dispatch(courseActions.toggleCreateModal(true));
  };

  return (
    <Box ml={{ base: 0, md: 20 }} p="10">
      <CreateCourseMd />

      <Flex my={4} align="center">
        <Text flex={1} fontSize="2xl">
          Courses
        </Text>
        <Button
          colorScheme="red"
          leftIcon={<AddIcon />}
          onClick={() => handleOpenCreateCourseModal()}
        >
          Create
        </Button>
      </Flex>

      <Box w="100%" p={4} color="gray.500" borderWidth="1px" borderRadius="lg">
        <Flex m={2} justify={{ base: 'center', md: 'end' }}>
          <IconButton
            aria-label="Reload table"
            variant="link"
            onClick={handleOnRefresh}
            icon={<RepeatIcon />}
          />
          <InputGroup width={{ base: '100%', md: '350px' }}>
            <InputRightElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputRightElement>
            <Input
              name="query"
              placeholder="Search"
              value={paginationParams.query}
              onChange={(event) => handleParamsChange('query', event)}
            />
          </InputGroup>
        </Flex>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Course</Th>
                <Th>Category</Th>
                <Th>Code</Th>
                <Th>Owner</Th>
                <Th>Published</Th>
              </Tr>
            </Thead>

            <Tbody>
              {status === 'loading' ? (
                <TableItemLoader numberOfRows={5} numberOfColumns={6} />
              ) : (
                courses?.map((course) => (
                  <Tr key={course.id}>
                    <Td isNumeric>{course.id}</Td>
                    <Td>
                      <ChakraLink
                        as={ReactRouterLink}
                        color="blue.500"
                        to={`/admin/courses/${course.id}`}
                      >
                        {course.name}
                      </ChakraLink>
                    </Td>
                    <Td>{course.category.name}</Td>
                    <Td>
                      <Text as="b"> {course.code}</Text>
                    </Td>
                    <Td>{course.owner?.name}</Td>
                    <Td>
                      <Badge
                        colorScheme={course.isPublished ? 'green' : 'purple'}
                      >
                        {course.isPublished ? 'Published' : 'Draft'}
                      </Badge>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>

            <Tfoot>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Course</Th>
                <Th>Category</Th>
                <Th>Code</Th>
                <Th>Owner</Th>
                <Th>Published</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>

        <Flex m={2} justify={{ base: 'center', md: 'end' }} align="center">
          <RowsPerPage
            pageSize={paginationParams.pageSize}
            onChange={(event) => handleParamsChange('pageSize', event)}
          />
          <TableNavigation
            metaData={metaData}
            onNext={(event) =>
              handleParamsChange('incrementCurrentPage', event)
            }
            onPrevious={(event) =>
              handleParamsChange('decrementCurrentPage', event)
            }
          />
        </Flex>
      </Box>
    </Box>
  );
}

export default CourseListPage;
